'use client';

import React, { ReactNode, useContext } from 'react';
import clsx from 'clsx';
import { LogoLoading } from '@/components/LoadingIndicator';
import { MainLayoutContext } from '@/components/MainLayout/context/mainLayoutContext';
import classes from '@/components/MainLayout/styles/mainLayout.module.scss';

interface IContentLayout {
    children: ReactNode;
    classes?: Record<string, string>;
}

const ContentLayout = ({ children, classes: propsClasses }: IContentLayout) => {
    const { loading } = useContext(MainLayoutContext);

    return (
        <>
            {loading && (
                <div className={clsx(classes.load, propsClasses?.root)}>
                    <LogoLoading isProgressBar />
                </div>
            )}
            {children}
        </>
    );
};

export default ContentLayout;
